import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';  
import { DataService } from '../../data.service';
import { DatePipePipes } from '../../date.pipe';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
  providers: [ DatePipePipes ]
})
export class CarouselComponent implements OnInit {
  bannerimg:any[]=[];
  constructor(config: NgbCarouselConfig,private service: DataService,
    public datePipe: DatePipePipes) {  
    config.interval = 3000;  
    config.wrap = true;  
    config.keyboard = false;  
    config.pauseOnHover = true;  
  }  

  ngOnInit() {
    this.banner();
  }

  banner(){
    let inputJson = {
      DbCon: this.service.db,
      Schoolcode:this.service.scode
    }
    this.service.GetWebsiteScrollerImages(inputJson).subscribe(
      (data : any[]) => {
       
        if(data['Status'] == 'Success') {
          this.bannerimg = data['WebsiteScrollerImages'];
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }
}
