import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';  

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { ReactiveFormsModule } from  '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatePipePipes, DateFormat  } from './date.pipe';
import { CommonDirective } from './common.directive';

import { HeaderComponent } from './views/header/header.component';
import { FooterComponent } from './views/footer/footer.component';
import { HomeComponent } from './views/home/home.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { PrincipalMsgComponent } from './views/principal-msg/principal-msg.component';
import { ManagerMsgComponent } from './views/manager-msg/manager-msg.component';
import { SalientFeaturesComponent } from './views/salient-features/salient-features.component';
import { ObjectivesComponent } from './views/objectives/objectives.component';
import { AddmissionComponent } from './views/addmission/addmission.component';
import { DisciplineComponent } from './views/discipline/discipline.component';
import { CodeOfParentsComponent } from './views/code-of-parents/code-of-parents.component';
import { CodeOfStudentsComponent } from './views/code-of-students/code-of-students.component';
import { TransportationComponent } from './views/transportation/transportation.component';
import { FeeComponent } from './views/fee/fee.component';
import { SchoolTimingComponent } from './views/school-timing/school-timing.component';
import { ImgGalleryComponent } from './views/img-gallery/img-gallery.component';
import { VideoGalleryComponent } from './views/video-gallery/video-gallery.component';
import { OnlineFeeComponent } from './views/online-fee/online-fee.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { NoticesComponent } from './views/notices/notices.component';
import { ThanksComponent } from './views/thanks/thanks.component';
import { ExpectationsComponent } from './views/expectations/expectations.component';
import { PrayersComponent } from './views/prayers/prayers.component';
import { UniformComponent } from './views/uniform/uniform.component';
import { WithdrawalComponent } from './views/withdrawal/withdrawal.component';
import { ToppersComponent } from './views/toppers/toppers.component';
import { AchievementsComponent } from './views/achievements/achievements.component';
import { StuHeadsComponent } from './views/stu-heads/stu-heads.component';
import { CabinetComponent } from './views/cabinet/cabinet.component';
import { StaffGroupComponent } from './views/staff-group/staff-group.component';
import { GuestBookComponent } from './views/guest-book/guest-book.component';
import { SitemapComponent } from './views/sitemap/sitemap.component';
import { PatronComponent } from './views/patron/patron.component';
import { FatherComponent } from './views/father/father.component';
import { AdministratorComponent } from './views/administrator/administrator.component';
import { AppreciationComponent } from './views/appreciation/appreciation.component';
import { PledgeComponent } from './views/pledge/pledge.component';
import { LibraryComponent } from './views/library/library.component';
import { VacationComponent } from './views/vacation/vacation.component';
import { CarouselComponent } from './views/carousel/carousel.component';
import { RulesComponent } from './views/rules/rules.component';
import { CoFounderComponent } from './views/co-founder/co-founder.component';
import { Certificate1Component } from './views/certificate1/certificate1.component';
import { Certificate2Component } from './views/certificate2/certificate2.component';
import { Certificate3Component } from './views/certificate3/certificate3.component';
import { EnrollmentComponent } from './views/enrollment/enrollment.component';
import { RegistrationFeeComponent } from './views/registration-fee/registration-fee.component';
import { FaliureComponent } from './views/faliure/faliure.component';
import { CalenderComponent } from './views/calender/calender.component';
import { FeeStructureComponent } from './views/fee-structure/fee-structure.component';


@NgModule({
  declarations: [    
    AppComponent,
    DatePipePipes,
    DateFormat,
    CommonDirective,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutUsComponent,
    PrincipalMsgComponent,
    ManagerMsgComponent,
    SalientFeaturesComponent,
    ObjectivesComponent,
    AddmissionComponent,
    DisciplineComponent,
    CodeOfParentsComponent,
    CodeOfStudentsComponent,
    TransportationComponent,
    FeeComponent,
    SchoolTimingComponent,
    ImgGalleryComponent,
    VideoGalleryComponent,
    OnlineFeeComponent,
    ContactUsComponent,
    NoticesComponent,
    ThanksComponent,
    ExpectationsComponent,
    PrayersComponent,
    UniformComponent,
    WithdrawalComponent,
    ToppersComponent,
    AchievementsComponent,
    StuHeadsComponent,
    CabinetComponent,
    StaffGroupComponent,
    GuestBookComponent,
    SitemapComponent,
    PatronComponent,
    FatherComponent,
    AdministratorComponent,
    AppreciationComponent,
    PledgeComponent,
    LibraryComponent,
    VacationComponent,
    CarouselComponent,
    RulesComponent,
    CoFounderComponent,
    Certificate1Component,
    Certificate2Component,
    Certificate3Component,
    EnrollmentComponent,
    RegistrationFeeComponent,
    FaliureComponent,
    CalenderComponent,
    FeeStructureComponent,
  ],
  imports: [
    NgImageSliderModule,
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
