import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'date'
})
export class DatePipePipes implements PipeTransform {

  transform(time: any):any {
    var time24To12 = function(a) {
      //below date doesn't matter.
      return (new Date("1955-11-05T" + a + "Z")).toLocaleTimeString("bestfit", {
          timeZone: "UTC",
          hour12: !0,
          hour: "numeric",
          minute: "numeric"
      });
    };  
    return time24To12(time); 
  }

  tolocalDate(today) {
    var date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
    var date = date;
    return date;
  }

  toDbDate(today) {
    var date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
    var date = date;
    return date;
  }

  tolocalDateTime(today) {
    // var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    
    
    // var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    // var time = today.getHours() + ":" + today.getMinutes();
    // var dateTime = date+ ' ' +time;
    // return dateTime;

    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var hours = today.getHours() ;
    var AmOrPm = hours >= 12 ? 'pm' : 'am';
    hours = (hours % 12) || 12;
    var minutes = today.getMinutes();
    var sec = today.getSeconds();
    var finalTime = date+ " " + hours + ":" + minutes + ":" + sec + " " + AmOrPm; 
    return finalTime;
  }

  tolocalTime(today) {
    var hours = today.getHours() ;
    var AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12;
    var time = hours + ":" + today.getMinutes() + ":" + today.getSeconds() + " " + AmOrPm;
    return time;
  }

  secToMin(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return minutes + ':' + (value - minutes * 60);
  }

  msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
  
    return hrs + ':' + mins + ':' + secs;
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hrs, ") : "";
    // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " min, ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " sec") : "";

    // return hDisplay + mDisplay + sDisplay; 

    var hDisplay = h > 0 ? h : "00";
    var mDisplay = m > 0 ? m : "00";
    var sDisplay = s > 0 ? s : "00";

    return hDisplay + ':' + mDisplay + ':' + sDisplay; 
  }


  // timeToseconds(v) {
  //   var splitSpace = v.split(" ")
  //   var splitRes = splitSpace[0].toString().split(":")
  //   var h =  + splitRes[0]
  //   var m = splitRes[1]
  //   var s = splitRes[2]

  //   if(h) {
  //     var countStartSec = Math.floor(h * 60)
  //   }

  //   console.log(countStartSec)
  //   console.log(h + ' --' + m + ' --' + s)
    
  // }

}

@Pipe({
  'name':'DateFormat'
})
export class DateFormat implements PipeTransform {
  transform(value){
    var s=value;
    //value=s.replace("AMPM"," AMPM")
    value=s.replace("A", " A").replace("P", " P");
    var datePipe = new DatePipe("en-US");
    // console.log(value)
    //value = datePipe.transform(new Date(value).toDateString(), 'MMM d, y');
    value = datePipe.transform(new Date(value).toDateString(), 'dd-MM-yyyy');
    return value;
  }
}


