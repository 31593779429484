import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//All Components import here

import { HomeComponent } from './views/home/home.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { PrincipalMsgComponent } from './views/principal-msg/principal-msg.component';
import { ManagerMsgComponent } from './views/manager-msg/manager-msg.component';
import { SalientFeaturesComponent } from './views/salient-features/salient-features.component';
import { ObjectivesComponent } from './views/objectives/objectives.component';
import { AddmissionComponent } from './views/addmission/addmission.component';
import { DisciplineComponent } from './views/discipline/discipline.component';
import { CodeOfParentsComponent } from './views/code-of-parents/code-of-parents.component';
import { CodeOfStudentsComponent } from './views/code-of-students/code-of-students.component';
import { TransportationComponent } from './views/transportation/transportation.component';
import { FeeComponent } from './views/fee/fee.component';
import { SchoolTimingComponent } from './views/school-timing/school-timing.component';
import { ImgGalleryComponent } from './views/img-gallery/img-gallery.component';
import { VideoGalleryComponent } from './views/video-gallery/video-gallery.component';
import { OnlineFeeComponent } from './views/online-fee/online-fee.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { NoticesComponent } from './views/notices/notices.component';
import { ThanksComponent } from './views/thanks/thanks.component';
import { ExpectationsComponent } from './views/expectations/expectations.component';
import { PrayersComponent } from './views/prayers/prayers.component';
import { UniformComponent } from './views/uniform/uniform.component';
import { WithdrawalComponent } from './views/withdrawal/withdrawal.component';
import { ToppersComponent } from './views/toppers/toppers.component';
import { AchievementsComponent } from './views/achievements/achievements.component';
import { StuHeadsComponent } from './views/stu-heads/stu-heads.component';
import { CabinetComponent } from './views/cabinet/cabinet.component';
import { StaffGroupComponent } from './views/staff-group/staff-group.component';
import { GuestBookComponent } from './views/guest-book/guest-book.component';
import { SitemapComponent } from './views/sitemap/sitemap.component';
import { PatronComponent } from './views/patron/patron.component';
import { FatherComponent } from './views/father/father.component';
import { AdministratorComponent } from './views/administrator/administrator.component';
import { AppreciationComponent } from './views/appreciation/appreciation.component';
import { PledgeComponent } from './views/pledge/pledge.component';
import { LibraryComponent } from './views/library/library.component';
import { VacationComponent } from './views/vacation/vacation.component';
import { CarouselComponent } from './views/carousel/carousel.component';
import { RulesComponent } from './views/rules/rules.component';
import { CoFounderComponent } from './views/co-founder/co-founder.component';
import { Certificate1Component } from './views/certificate1/certificate1.component';
import { Certificate2Component } from './views/certificate2/certificate2.component';
import { Certificate3Component } from './views/certificate3/certificate3.component';
import { EnrollmentComponent } from './views/enrollment/enrollment.component';
import { RegistrationFeeComponent } from './views/registration-fee/registration-fee.component';
import { FaliureComponent } from './views/faliure/faliure.component';
import { CalenderComponent } from './views/calender/calender.component';
import { FeeStructureComponent } from './views/fee-structure/fee-structure.component';

//ends import here ---


const routes: Routes = [
  { path: '',   redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'principal-msg', component: PrincipalMsgComponent },
  { path: 'manager-msg', component: ManagerMsgComponent },
  { path: 'salient-features', component: SalientFeaturesComponent },
  { path: 'objectives', component: ObjectivesComponent },
  { path: 'addmission', component: AddmissionComponent },
  { path: 'discipline', component: DisciplineComponent },
  { path: 'code-of-parents', component: CodeOfParentsComponent },
  { path: 'code-of-students', component: CodeOfStudentsComponent },
  { path: 'transportation', component: TransportationComponent },
  { path: 'fee', component: FeeComponent },
  { path: 'school-timing', component: SchoolTimingComponent },
  { path: 'img-gallery', component: ImgGalleryComponent },
  { path: 'video-gallery', component: VideoGalleryComponent },
  { path: 'online-fee', component: OnlineFeeComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'notices', component: NoticesComponent },
  { path: 'notices/:id', component: NoticesComponent },
  { path: 'thanks/:amu', component: ThanksComponent },
  { path: 'expectations', component: ExpectationsComponent },
  { path: 'prayers', component: PrayersComponent },
  { path: 'uniform', component: UniformComponent },
  { path: 'withdrawal', component: WithdrawalComponent },
  { path: 'toppers', component: ToppersComponent },
  { path: 'achievements', component: AchievementsComponent },
  { path: 'stu-heads', component: StuHeadsComponent },
  { path: 'cabinet', component: CabinetComponent },
  { path: 'staff-group', component: StaffGroupComponent },
  { path: 'guest-book', component: GuestBookComponent },
  { path: 'sitemap', component: SitemapComponent },
  { path: 'patron', component: PatronComponent },
  { path: 'father', component: FatherComponent },
  { path: 'administrator', component: AdministratorComponent },
  { path: 'appreciation', component: AppreciationComponent },
  { path: 'pledge', component: PledgeComponent },
  { path: 'library', component: LibraryComponent },
  { path: 'vacation', component: VacationComponent },
  { path: 'carousel', component: CarouselComponent },
  { path: 'rules', component: RulesComponent }, 
  { path: 'co-founder', component: CoFounderComponent },
  { path: 'certificate1', component: Certificate1Component },
  { path: 'certificate2', component: Certificate2Component },
  { path: 'certificate3', component: Certificate3Component }, 
  { path: 'enrollment', component: EnrollmentComponent },
  { path: 'registration-fee', component: RegistrationFeeComponent }, 
  { path: 'faliure', component: FaliureComponent },
  { path: 'calender', component: CalenderComponent },
  { path: 'fee-structure', component: FeeStructureComponent },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
