import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
 import { DatePipePipes } from '../../date.pipe';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [ DatePipePipes ]
})
export class HeaderComponent implements OnInit {
onlinefee:boolean=false;
constructor(private service: DataService,
  public datePipe: DatePipePipes) { }

  ngOnInit() {
    let inputJson = {
      DbCon: this.service.db,
      Schoolcode:this.service.scode
    }
    this.service.ListOfBirthday(inputJson).subscribe(
      (data : any[]) => {
        if(data['Status'] == 'Success') {
          this.onlinefee=data['IsFee'];
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  
  }

  toggle() {
    var id = document.getElementById('collapsibleNavbar');
    console.log(id.className)
  }

}
