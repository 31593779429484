import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-discipline',
  templateUrl: './discipline.component.html',
  styleUrls: ['./discipline.component.css']
})
export class DisciplineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
