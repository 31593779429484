import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../environments/environment';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// declare var Noty:any;

@Injectable({
  providedIn: 'root'
})
export class DataService {

  serviceUrl: string = "";
  FileUploadurl: string = "";
  db:string="";
  scode:string="";
  online_fee:boolean=false;

  constructor(private http : HttpClient) { 
    this.serviceUrl = environment.serviceUrl;
    this.FileUploadurl = environment.fileUploadUrl;
    this.db=environment.DBCON;   
    this.scode=environment.SCODE; 
    this.online_fee=environment.online_fee;       
  }

  // alert(msg, type){
  //   var n = new Noty({
  //       text: '<strong>'+ msg +'</strong>',
  //       type   : type,
  //       progressBar: true,
  //       closeWith: ['click', 'button'],
  //       timeout:2000
  //     }).show();
  //     Noty.setMaxVisible(1,'notification');
  // }

  GetCalendar(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'GetCalendar', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 

  ListFeeSchedules(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'ListFeeSchedules', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 

  GetStudentDetails(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'GetStudentDetails', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 

  InitiateFeePayment(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'InitiateFeePayment', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 

  GetAllEvents4(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'GetAllEvents4', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 

  GetAllEvents5(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'GetAllEvents5', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 
  GetEventType(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'GetEventType', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 
  GetAllEvents6(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'GetAllEvents6', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 
  ShowMyCreatedNotices1(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'ShowMyCreatedNotices1', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 
  GetAllEvents7(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'GetAllEvents7', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 
//shubham create function 8_10_2021
ListofQuotes(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'ListofQuotes', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 

  GetAllTeachers(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'GetAllTeachers', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 


  ListOfBirthday(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'ListOfBirthday', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 
  
  GetWebsiteScrollerImages(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'GetWebsiteScrollerImages', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  } 




  IssueRegistration(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'IssueRegistration', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  }

  RetreiveRegistrationDetails(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'RetreiveRegistrationDetails', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  }

  SaveRegistrationForm(inputJson) {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(this.serviceUrl + 'SaveRegistrationForm', inputJson, {headers: headers})
    .pipe(
      map((data) => {
        return data;
      }), catchError( error => {
        console.log(error);
        return throwError(error);
      })
    )
  }

  uploadFile(inputJson) {    
    let uploadURL = this.FileUploadurl;
    return this.http.post<any>(uploadURL, inputJson)
  }

}
