import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { DomSanitizer  } from '@angular/platform-browser';
import { $ } from 'protractor';

@Component({
  selector: 'app-img-gallery',
  templateUrl: './img-gallery.component.html',
  styleUrls: ['./img-gallery.component.css']
})
export class ImgGalleryComponent implements OnInit {
  selectedHero:number=1;
  images: any[]=[];
  slideIndex = 1;
  Height: any;
  isSuccess: boolean = false;
  eventtype:any[]=[];

  // imageObject = [
  // {
  //   "Title" : "Title1",
  //   "ImgArray" : [{
  //     image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
  //     thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
  //     title: 'Hummingbirds are amazing creatures'
  //   }, {
  //     image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg',
  //     thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg'
  //   },{
  //     image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
  //     thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
  //     title: 'Example with title.'
  //   },{
  //     image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
  //     thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
  //     title: 'Hummingbirds are amazing creatures'
  //   }]
  // }, 
  // {
  //   "Title" : "Title2",
  //   "ImgArray" : [{
  //       image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
  //       thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
  //       title: 'Hummingbirds are amazing creatures'
  //   }, {
  //       image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg',
  //       thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg'
  //   },{
  //       image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
  //       thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
  //       title: 'Example with title.'
  //   },{
  //       image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg',
  //       thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg'
  //   }, {
  //       image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
  //       thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
  //       title: 'Example two with title.'
  //   }]
  // }];

  constructor(private service: DataService,
    public sanitizer: DomSanitizer) { }

  ngOnInit() {
    // this.getImages();
    this.calheight();
    this.GetEventType()
  }

  calheight() {
    this.Height = window.innerHeight - 200;
  }
  GetEventType(){
    let inputJson = {
      DbCon: this.service.db,
    }
    this.service.GetEventType(inputJson).subscribe(
      (data : any[]) => {
        
        if(data['Status'] == 'Success') {
          // this.isSuccess = true;
          this.eventtype = data['EventTypes'];
          
          console.log(JSON.stringify( this.eventtype))
          this.getImages( this.eventtype[0].EventTypeId);
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }

  onSelect(hero): void {
    this.selectedHero = hero;
this.getImages(hero)
// alert(hero)
  }
  getImages(id) { 
    let inputJson = {
      DbCon: this.service.db,
      Entity: "",
      EntityId: -1,
      EventTypeId:id,
      IsVideo: false,
     
      Schoolcode:this.service.scode
    }
    this.service.GetAllEvents6(inputJson).subscribe(
      (data : any[]) => {
        
        if(data['Status'] == 'Success') {
          this.isSuccess = true;
          this.images = data['Events'];
          console.log(JSON.stringify(data))
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }
}
