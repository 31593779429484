import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  feeForm: FormGroup;
  errorModal:boolean = false;
  myMsg: string;

  constructor(private fb: FormBuilder) { 
    this.feeForm = this.fb.group({     
      name: [''],
      address: [''],
      mobile: [''],
      email: [''],
      subject: [''],
      message: [''],
    })
  }

  ngOnInit() {
  }

  submit() {

  }

  reset() {

  }

}
