import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { DomSanitizer  } from '@angular/platform-browser';

@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.css']
})
export class VideoGalleryComponent implements OnInit {
 
  selectedHero:number=1;
  images: any[]=[];
  isSuccess: boolean = false;
  eventtype:any[]=[];

  constructor(private service: DataService,
    public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.GetEventType();
  }
  
  GetEventType(){
    let inputJson = {
      DbCon: this.service.db,
    }
    this.service.GetEventType(inputJson).subscribe(
      (data : any[]) => {
        
        if(data['Status'] == 'Success') {
          // this.isSuccess = true;
          this.eventtype = data['EventTypes'];
          
          console.log(JSON.stringify( this.eventtype))
          this.getvideos( this.eventtype[0].EventTypeId);
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }
  onSelect(hero): void {
    this.selectedHero = hero;
this.getvideos(hero)
// alert(hero)
  }
  getvideos(id) {
    var today = new Date();   
    let inputJson = {
      DbCon: this.service.db,
      Entity: "",
      EntityId: -1,
      EventTypeId:id,
      IsVideo: true,
      
      Schoolcode:this.service.scode
    }
    this.service.GetAllEvents7(inputJson).subscribe(
      (data : any[]) => {
        if(data['Status'] == 'Success') {
          this.isSuccess = true;
          this.images = data['Events'];
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }

  getSantizeUrl(url) {

		var regExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
    var match = url.match(regExp);
    
    if(match != null) {
      var UrlLink1 = "https://player.vimeo.com/video/" + match[1];
      return this.sanitizer.bypassSecurityTrustResourceUrl(UrlLink1);

    } else {

    var regExp2 = /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/;
    var match2 = url.match(regExp2);

      if (match2 != null) {  
          var urlLink2 = 'https://www.youtube.com/embed/' + match2[1] + "?autoplay=0&rel=0&showinfo=0";
          return this.sanitizer.bypassSecurityTrustResourceUrl(urlLink2); 
      }
    }
   
  }



}
