import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificate2',
  templateUrl: './certificate2.component.html',
  styleUrls: ['./certificate2.component.css']
})
export class Certificate2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
