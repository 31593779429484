import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prayers',
  templateUrl: './prayers.component.html',
  styleUrls: ['./prayers.component.css']
})
export class PrayersComponent implements OnInit {

  select_id: string;

  constructor() { }

  ngOnInit() {
    this.toggle(1);    
  }

  toggle(pvarId){
    this.select_id = pvarId;
  }

}
