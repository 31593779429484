import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { DatePipePipes } from '../../date.pipe';

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.css'],
  providers: [ DatePipePipes ]
})
export class CalenderComponent implements OnInit {

  CalendarEvents: any[];
  notices: any[];
  myArray: any[]=[];

  constructor(private service: DataService,
    public datePipe: DatePipePipes) { }

  ngOnInit() {
    this.getCalendarEvents();
    //this.Notice()
  }
  Notice() {
    
    let inputJson = {
      DbCon: this.service.db,
      Schoolcode:this.service.scode
    }
    // alert(this.service.scode)
    this.service.ShowMyCreatedNotices1(inputJson).subscribe(
      (data : any[]) => {
        if(data['Status'] == 'Success') {
          this.notices = data['ShowNotices'];
          // alert( JSON.stringify(this.notices['NoticeImages']))
         //this.notice0=this.notices[0].Description;
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
}
getCalendarEvents() {
    var today = new Date();   
    let inputJson = {
      DbCon: this.service.db,
      CurentDate: this.datePipe.toDbDate(today)
    }
    this.service.GetCalendar(inputJson).subscribe(
      (data : any[]) => {
        if(data['Status'] == 'Success') {
          this.CalendarEvents = data['CalenderData'];
          let obj = {};
          for(var x= 0; x < 3; x++) { 
            obj={}; 
            obj['Details'] = this.CalendarEvents[x].Details;
            obj['HolidayDate'] = this.CalendarEvents[x].HolidayDate;               
            this.myArray.push(obj);     
          }             
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
}

}
