import { LiteralMapEntry } from "@angular/compiler/src/output/output_ast";
import { Component, HostListener, ViewChild, ElementRef,OnInit} from "@angular/core";
import { DataService } from '../../data.service';
import { DatePipePipes } from '../../date.pipe';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [ DatePipePipes ]
})
export class HomeComponent implements OnInit {
  stucount: number = 0;
  teacher: number = 0;
  class: number = 0;
  notices: any[];
  CalendarEvents: any[];
  todaybirth:any[];
  myArray: any[]=[];
  BdayArr:any[]=[];
  quotes:any[]=[];
  ListOfQuote:any[]=[];
  birth:any[]=[];
  currentPage: number = 1;
  pagePosition: string = "0%";
  notice0: string = "";
  cardsPerPage: number;
  totalPages: number;
  overflowWidth: string;
  cardWidth: string;
  containerWidth: number;
  bdshow:boolean=false;
  @ViewChild("container", { static: true, read: ElementRef })
  container: ElementRef;
  @HostListener("window:resize") windowResize() {
    let newCardsPerPage = this.getCardsPerPage();
    if (newCardsPerPage != this.cardsPerPage) {
      this.cardsPerPage = newCardsPerPage;
      this.initializeSlider(this.todaybirth.length);
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
        this.populatePagePosition();
      }
    }
  }
  constructor(private service: DataService,
    public datePipe: DatePipePipes) { }

  ngOnInit() {
    this.Notice();
   this.getCalendarEvents();
   this.ListofQuotes();
   this.birthdy();

     this.cardsPerPage = this.getCardsPerPage();
  }

  initializeSlider(total) {
    this.totalPages = Math.ceil(total / this.cardsPerPage);
    this.overflowWidth = `calc(${this.totalPages * 100}% + ${this.totalPages *
      10}px)`;
    this.cardWidth = `calc((${100 / this.totalPages}% - ${this.cardsPerPage *
      10}px) / ${this.cardsPerPage})`;
  }

  getCardsPerPage() {
    return Math.floor(this.container.nativeElement.offsetWidth / 200);
  }

  changePage(incrementor) {
    this.currentPage += incrementor;
    this.populatePagePosition();
  }

  populatePagePosition() {
    this.pagePosition = `calc(${-100 * (this.currentPage - 1)}% - ${10 *
      (this.currentPage - 1)}px)`;
  }


  ListofQuotes() {
    
      let inputJson = {
        DbCon: this.service.db,
      }
      this.service.ListofQuotes(inputJson).subscribe(
        (data : any[]) => {
          if(data['Status'] == 'Success') {
            this.ListOfQuote = data['ListOfQuote'];
                      
          } else {
            console.log((data['Message']));
            return false;
          }
        }, (error) => {
          console.log(error);
        }
      )
  }
  Notice() {
    
    let inputJson = {
      DbCon: this.service.db,
      Schoolcode:this.service.scode
    }
    this.service.ShowMyCreatedNotices1(inputJson).subscribe(
      (data : any[]) => {
        if(data['Status'] == 'Success') {
          this.notices = data['ShowNotices'];
         this.notice0=this.notices[0].Description;
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
}

  birthdy(){
    let inputJson = {
      DbCon: this.service.db,
      Schoolcode:this.service.scode
    }
    this.service.ListOfBirthday(inputJson).subscribe(
      (data : any[]) => {
        
        if(data['Status'] == 'Success') {
          this.birth = data['UpcomingBirthdays'];
          this.todaybirth = data['TodayBirthdays'];
          this.stucount=data['UserCount'].StudentCount;
          this.teacher=data['UserCount'].TeachersCount;
          this.class=data['UserCount'].ClassCount;
          this.service.online_fee=data['IsFee'];
            this.initializeSlider(this.todaybirth.length);
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }


  getCalendarEvents() {
    
    // console.log(this.service.db)
    // alert(this.service.db)
      var today = new Date();   
      let inputJson = {
        // DbCon: "JALJOSEPHCN",
        DbCon: this.service.db,
        CurentDate: this.datePipe.toDbDate(today)
      }
     
      this.service.GetCalendar(inputJson).subscribe(
        (data : any[]) => {
          if(data['Status'] == 'Success') {
            this.CalendarEvents = data['CalenderData'];
            let obj = {};
            for(var x= 0; x < 3; x++) { 
              obj={}; 
              obj['Details'] = this.CalendarEvents[x].Details;
              obj['HolidayDate'] = this.CalendarEvents[x].HolidayDate;               
              this.myArray.push(obj); 
               
             // console.log(this.myArray)   
            }             
          } else {
            console.log((data['Message']));
            return false;
          }
        }, (error) => {
          console.log(error);
        }
      )
  }


}
