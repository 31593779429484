import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { DomSanitizer  } from '@angular/platform-browser';

@Component({
  selector: 'app-staff-group',
  templateUrl: './staff-group.component.html',
  styleUrls: ['./staff-group.component.css']
})
export class StaffGroupComponent implements OnInit {
  staff: any[]=[];
  nonstaff:any[]=[];
  

  // constructor() { }
  constructor(private service: DataService,
    public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.GetStaff();
   this.GetnonStaff();
  }
  GetStaff(){
    let inputJson = {
      DbCon: this.service.db,
IsTeacher:true,

Schoolcode:this.service.scode
    }
    this.service.GetAllTeachers(inputJson).subscribe(
      (data : any[]) => {
        
        if(data['Status'] == 'Success') {
          // this.isSuccess = true;
          this.staff = data['Teachers'];
          
          console.log(JSON.stringify( this.staff))
          // this.getImages( this.staff[0].EventTypeId);
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }
  GetnonStaff(){
    let inputJson = {
      DbCon: this.service.db,
IsTeacher:false,

Schoolcode:this.service.scode
    }
    this.service.GetAllTeachers(inputJson).subscribe(
      (data : any[]) => {
        
        if(data['Status'] == 'Success') {
          // this.isSuccess = true;
          this.nonstaff = data['Teachers'];
          
          console.log(JSON.stringify( this.nonstaff))
          // this.getImages( this.staff[0].EventTypeId);
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }
}
