import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.css']
})
export class EnrollmentComponent implements OnInit {

  feeForm: FormGroup;
  errorModal = false;
  myMsg: string;
  submitted = false;
  loader = false;

  constructor(private fb: FormBuilder,
    private service: DataService) { 
    this.feeForm = this.fb.group({     
      stu_Name: ['', [Validators.required]],
      father_Name: ['', [Validators.required]],
      mobile: ['', [Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), Validators.maxLength(10)]],     
    })
  }

  get f() { return this.feeForm.controls; }

  ngOnInit() {
  }

  submit() {    
    this.submitted = true;
    if (this.feeForm.invalid) {
      return;
    } else {
      this.loader = true;
      var inputData =  this.feeForm.value;
      let inputJson = {
        DbCon: "JALJOSEPHCN",
        Code: "SJSJ",
        Student: inputData.stu_Name,
        ParentName: inputData.father_Name,
        ContactNo: inputData.mobile
      }
      // console.log(inputJson)
      this.service.IssueRegistration(inputJson).subscribe(
        (data : any[]) => {
          if(data['Status'] == 'Success') {
            this.feeForm.reset();
            this.loader = false;
            this.submitted = false;
            open('http://radicalseeds.com/ExtWebService/SJSJ/'+data['Message'], '_blank');
          } else {
            this.loader = false;
            this.openModal(data['Message']);
            return false;
          }
        }, (error) => {
          this.loader = false;
          console.log(error);
        }
      )
    }
  }

  openModal(msg) {
    this.myMsg = msg;
    this.errorModal = true;
  }

  closeModal() {
    this.myMsg = "";
    this.errorModal = false;
  }
  
}
