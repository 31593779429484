import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificate3',
  templateUrl: './certificate3.component.html',
  styleUrls: ['./certificate3.component.css']
})
export class Certificate3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
