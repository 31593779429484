import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-code-of-students',
  templateUrl: './code-of-students.component.html',
  styleUrls: ['./code-of-students.component.css']
})
export class CodeOfStudentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
