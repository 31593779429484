import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { DatePipePipes } from '../../date.pipe';
@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.css'],
  providers: [ DatePipePipes ]

})
export class AchievementsComponent implements OnInit {
  birth:any[]=[];
  constructor(private service: DataService,
    public datePipe: DatePipePipes) { }
  ngOnInit() {
    this.birthdy();
  }
  birthdy(){
    let inputJson = {
      DbCon: this.service.db,
      Schoolcode:this.service.scode
    }
    this.service.ListOfBirthday(inputJson).subscribe(
      (data : any[]) => {
        
        if(data['Status'] == 'Success') {
          this.birth = data['UpcomingBirthdays'];
          
          console.log(JSON.stringify( this.birth))
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }
}
