import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uniform',
  templateUrl: './uniform.component.html',
  styleUrls: ['./uniform.component.css']
})
export class UniformComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
