import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expectations',
  templateUrl: './expectations.component.html',
  styleUrls: ['./expectations.component.css']
})
export class ExpectationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
