import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-co-founder',
  templateUrl: './co-founder.component.html',
  styleUrls: ['./co-founder.component.css']
})
export class CoFounderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
