import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.css']
})
export class ThanksComponent implements OnInit {

  form: FormGroup;  
  paramId: number;
  uploadResponse;
  errorModal:boolean = false;
  myMsg: string;
  FileName: string;
  loader = false;
  goToHome = false;

  constructor(private service: DataService, 
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder) {
    this.form = this.fb.group({     
      avatar: [''],    
    })
  }

  ngOnInit() {
    if(this.route.snapshot.paramMap.get('amu') != null || this.route.snapshot.paramMap.get('amu') != undefined) {
      this.paramId = parseInt(this.route.snapshot.paramMap.get('amu'));
      console.log(this.paramId)
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('avatar').setValue(file);
    } 
  }

  onSubmit() {
    if(this.form.get('avatar').value == "" || this.form.get('avatar').value == null || this.form.get('avatar').value == undefined) {
      this.openModal("Please select file to uplaod");
      return false;
    }
    const formData = new FormData();
    formData.append('file', this.form.get('avatar').value);
    formData.append('SchoolCode', "SJSJ");
    this.loader = true;
    this.service.uploadFile(formData).subscribe(     
      (data) => {        
        this.uploadResponse = data;
        if(data['success'] == true) {
         this.FileName = data['file_name'];
          if(this.FileName != "" && this.FileName != null) {
            this.SaveRegnForm();
          }          
        } else {
          this.loader = false;
          this.openModal(data['file_name']);
          console.log(data['file_name']);
          return false;
        }
      },
      (err) => {   
        this.loader = false;       
        console.log(err);
        return false;
      }
    );
  }

  SaveRegnForm() {
    let inputJson = {
      DbCon: this.service.db,
     
      Filename: this.FileName,
      FeePaymentTransactionId: this.paramId
    }
    console.log(inputJson);  
    this.service.SaveRegistrationForm(inputJson).subscribe(
      (data : any[]) => {
        if(data['Status'] == 'Success') {
          this.loader = false;
          this.goToHome = true;    
          this.openModal(data['Message']);    
        } else {
          this.loader = false;
          this.openModal(data['Message']);
          return false;
        }
      }, (error) => {
        this.loader = false;
        console.log(error);
        return false;
      }
    )
  }

  openModal(msg) {
    this.myMsg = msg;
    this.errorModal = true;
  }

  closeModal() {
    this.myMsg = "";
    this.errorModal = false;
    if(this.goToHome == true) {
      this.router.navigateByUrl('/home');
    }  
  }

}
