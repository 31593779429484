import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../data.service';
import { DatePipePipes } from '../../date.pipe';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration-fee',
  templateUrl: './registration-fee.component.html',
  styleUrls: ['./registration-fee.component.css'],
  providers: [ DatePipePipes ]
})
export class RegistrationFeeComponent implements OnInit {

  feeForm: FormGroup;
  details: any[];
  errorModal:boolean = false;
  myMsg: string;
  
  constructor(private fb: FormBuilder,
    private service: DataService,
    public datePipe: DatePipePipes,
    private router: Router) {
    this.feeForm = this.fb.group({     
      RegNo: ['', [Validators.required]],    
      stu_name: [''],     
      fatherName: [''],
      mobile: [''],
      amount: [''],
    })
  }

  ngOnInit() {
 
  }

  GetRecord() {

    var feedata = this.feeForm.value;

    if(feedata.RegNo == "" || feedata.RegNo == null || feedata.RegNo == undefined) {
      this.openModal("Please enter registration number");
      return false;
    }

    var inputJson = {      
      DbCon: this.service.db,
     
      StrInfo: feedata.RegNo,
    }
    console.log(JSON.stringify(inputJson, undefined, 2));
    this.service.RetreiveRegistrationDetails(inputJson).subscribe(
      (data : any[]) => {
        if(data['Status'] == 'Success') {
         this.details = data['EnrollInformation'];
         this.PatchDetails(this.details); 

        } else {
          this.openModal(data['Message']);
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }

  payfee() {  

  if(this.details == undefined || this.details == null || this.details.length == 0) {
      this.openModal("Please enter the details to proceed");
      return false;
  } 

  if(this.details['IsFeeAlreadyPaid'] == true) {
    this.router.navigate(['/thanks', this.details['FeePaymentTransactionId']]);
  } else {    
      var inputJson = {
        Amount: this.details['FeeAmount'],
        DbCon: this.service.db,
     
        FeeReceiptId: this.details['FeeReceiptId'],
        ParentId: -1,
        SchoolId: this.details['SchoolId'],
      }
      console.log(inputJson);
      this.service.InitiateFeePayment(inputJson).subscribe(
        (data : any[]) => {
          if(data['Status'] == 'Success') {
            var ID = data['ID'];          
            if(this.details['IsFeeAlreadyPaid'] == false) {
              this.refresh();
              window.location.href = "http://radicalseeds.com/Payment/Default.aspx?TraxID="+ID+"&XCDOB=JALJOSEPHCN"; 
            }          
          } else {
            this.openModal(data['Message']);
            return false;
          }
        }, (error) => {
          console.log(error);
        }
      )
    }       
  }

  refresh() {
    this.feeForm.reset();
    this.details = [];
  }

  PatchDetails(data) {
    this.feeForm.patchValue({     
      stu_name: data.Student,
      mobile: data.ContactNo,
      fatherName: data.ParentName,
      amount: data.FeeAmount
    })
  }

  openModal(msg) {
    this.myMsg = msg;
    this.errorModal = true;
  }

  closeModal() {
    this.myMsg = "";
    this.errorModal = false;
  }

}
