import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-principal-msg',
  templateUrl: './principal-msg.component.html',
  styleUrls: ['./principal-msg.component.css']
})
export class PrincipalMsgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
