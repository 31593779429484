import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../data.service';
import { DatePipePipes } from '../../date.pipe';
import { Router } from '@angular/router';

@Component({
  selector: 'app-online-fee',
  templateUrl: './online-fee.component.html',
  styleUrls: ['./online-fee.component.css'],
  providers: [ DatePipePipes ]
})
export class OnlineFeeComponent implements OnInit {

  feeForm: FormGroup;
  QuarterList: any[];
  details: any[];
  errorModal:boolean = false;
  myMsg: string;
  
  constructor(private fb: FormBuilder,
    private service: DataService,
    public datePipe: DatePipePipes,
    private router: Router) {
    this.feeForm = this.fb.group({
      headCount: ['-1', [Validators.required]],
      date: [''],
      RegNo: ['', [Validators.required]],
      class: [''],
      stu_name: [''],
      DOB: [''],
      phNo: [''],
      motherName: [''],
      fatherName: [''],
      amount: [''],
      payment: ['']
    })
  }

  ngOnInit() {
    this.ListofQuarter();
    this.getTodayDate();  
  }

  getTodayDate() {
    var today = new Date();
    this.feeForm.patchValue({
      date: this.datePipe.tolocalDate(today)
    })
  }

  ListofQuarter() {
    let inputJson = {
      // DbCon: "STMARYCN"
      DbCon: this.service.db,
     
    }
    this.service.ListFeeSchedules(inputJson).subscribe(
      (data : any[]) => {
        if(data['Status'] == 'Success') {
          this.QuarterList = data['ListOfFeeSchedule'];
        } else {
          console.log((data['Message']));
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }

  GetRecord() {

    var feedata = this.feeForm.value;

    if(feedata.headCount == "-1") {
      this.openModal("Please select fee quarter");
      return false;
    }

    if(feedata.RegNo == "" || feedata.RegNo == null || feedata.RegNo == undefined) {
      this.openModal("Please enter registration number");
      return false;
    }

    var inputJson ={
      AdmNo: feedata.RegNo,
      // DbCon: "STMARYCN",
      DbCon: this.service.db,
      
      FeeScheduleId: parseInt(feedata.headCount)
    }
    console.log(JSON.stringify(inputJson, undefined, 2));
    this.service.GetStudentDetails(inputJson).subscribe(
      (data : any[]) => {
        if(data['Status'] == 'Success') {
         this.details = data['Details'];
         this.PatchDetails(this.details);
        } else {
          this.openModal(data['Message']);
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }

  payfee() {  
   if(this.details == undefined || this.details == null || this.details.length == 0) {
    this.openModal("Please enter the details to proceed");
    this.refresh();
    return false;
   } else {
    var inputJson = {
      Amount: this.details['Amount'],
      DbCon: this.service.db,
      
      FeeReceiptId: this.details['FeeReceiptId'],
      ParentId: this.details['ParentId'],
      SchoolId: 58
    }
    console.log(inputJson);
    this.service.InitiateFeePayment(inputJson).subscribe(
      (data : any[]) => {
        if(data['Status'] == 'Success') {
          var ID = data['ID'];
          this.refresh();
          window.location.href = "http://radicalseeds.com/Payment/Default.aspx?TraxID="+ID+"&XCDOB=JALJOSEPHCN"; 
        } else {
          this.openModal(data['Message']);
          return false;
        }
      }, (error) => {
        console.log(error);
      }
    )
   }    
  }

  refresh() {
    this.feeForm.reset();
    this.getTodayDate();
    this.details = [];
    this.feeForm.controls['headCount'].patchValue(-1);
  }

  PatchDetails(data) {
    this.feeForm.patchValue({     
      class: data.Class,
      stu_name: data.Name,
      DOB: data.DOB,
      phNo: data.ContactNo,
      motherName: data.MotherName,
      fatherName: data.FatherName,
      amount: data.Amount.toFixed(2),
      payment: data.AmountPending.toFixed(2)
    })
  }

  openModal(msg) {
    this.myMsg = msg;
    this.errorModal = true;
  }

  closeModal() {
    this.myMsg = "";
    this.errorModal = false;
  }

}
