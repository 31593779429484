import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faliure',
  templateUrl: './faliure.component.html',
  styleUrls: ['./faliure.component.css']
})
export class FaliureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
