import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { DatePipePipes } from '../../date.pipe';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.css'],
  providers: [ DatePipePipes ]
})
export class NoticesComponent implements OnInit {

  CalendarEvents: any[];
  type: number;
  notices: any[];
  constructor(private service: DataService,
    public datePipe: DatePipePipes,
    private route: ActivatedRoute) { }

  ngOnInit() {
    var getParms = this.route.snapshot.params.id;
    if(getParms != null || getParms != undefined) {
      this.type = getParms;
    } else {
      this.type = 0;
      this.getCalendarEvents();
    } 
  
  this.Notice();
}
Notice() {
  
  let inputJson = {
    DbCon: this.service.db,
    Schoolcode:this.service.scode
  }
 
  this.service.ShowMyCreatedNotices1(inputJson).subscribe(
    (data : any[]) => {
      if(data['Status'] == 'Success') {
        this.notices = data['ShowNotices'];
       
      } else {
        console.log((data['Message']));
        return false;
      }
    }, (error) => {
      console.log(error);
    }
  )
}
  getCalendarEvents() {
      var today = new Date();   
      let inputJson = {
        DbCon: "MCMConnectionString",
        CurentDate: this.datePipe.toDbDate(today)
      }
      this.service.GetCalendar(inputJson).subscribe(
        (data : any[]) => {
          if(data['Status'] == 'Success') {
            this.CalendarEvents = data['CalenderData'];
          } else {
            console.log((data['Message']));
            return false;
          }
        }, (error) => {
          console.log(error);
        }
      )
  }

}
